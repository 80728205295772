export const footer = {
  top: [
    {
      title: "Policies",
      url: "https://precollege.brown.edu/policies/",
    },
    {
      title: "Connect With Us",
      url: "https://precollege.brown.edu/connect/",
    },
    {
      title: "FREQUENTLY ASKED QUESTIONS ",
      url: "https://precollege.brown.edu/frequently-asked-questions-faq",
    },
  ],
  bottom: [
    {
      title: "Campus Safety",
      url: "https://dps.brown.edu/",
    },
    {
      title: "Accessibility",
      url: "https://www.brown.edu/website-accessibility",
    },
  ],
  social: [
    {
      platform: "tiktok",
      url: "https://www.tiktok.com/@brownprecollege",
    },
    {
      platform: "instagram",
      url: "https://www.instagram.com/BrownPreCollege/",
    },
    {
      platform: "facebook",
      url: "https://www.facebook.com/brownprecollege",
    },
    {
      platform: "youtube",
      url: "https://www.youtube.com/user/BrownPreCollege",
    },
    {
      platform: "linkedin",
      url: "https://www.linkedin.com/company/brown-university-pre-college-programs/",
    },
  ],
  email: "precollege@brown.edu",
};

export const header = {
  forYou: [
    {
      title: "Enrolled Students",
      url: "https://precollege.brown.edu/student-life/enrolled-students",
    },
    {
      title: "Instructors",
      url: "https://precollege.brown.edu/instructors",
    },
    { title: "Alumni", url: "https://precollege.brown.edu/alumni" },
  ],
  about: { title: "About", url: "https://precollege.brown.edu/about" },
  home: {
    title: "brown.edu",
    url: "https://brown.edu",
  },
  login: {
    title: "Login",
    url: "https://brownuniversity.force.com/PCPortal/TX_SiteLogin?startURL=/PCPortal/",
  },
};

import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri:
    process.env.NODE_ENV !== "production"
      ? "https://webservices-proxy.cis-qas.brown.edu"
      : "https://webservices-proxy.brown.edu",
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined",
});

export default client;

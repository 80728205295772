import React from "react";
import styled from "styled-components";
import { shape } from "prop-types";
import { formatDate } from "../helpers";
import { FONTS, COLORS, MEDIA } from "./shared_styles";

function DetailSection({ section }) {
  const {
    startDate,
    endDate,
    numWeeks,
    meetingTimes,
    status,
    instructors,
    courseNumber,
    format,
    supplementalFee,
  } = section;

  const hasFee = supplementalFee && supplementalFee > 0;

  return (
    <List>
      <LI>
        <Title>Dates:</Title> {formatDate(startDate, false)} -{" "}
        {formatDate(endDate, false)}
      </LI>
      <LI>
        <Title>Duration:</Title> {numWeeks} weeks
      </LI>
      <LI>
        <Title>Meeting Time:</Title> {meetingTimes}
      </LI>
      <LI>
        <Title> Status:</Title>{" "}
        <span
          style={{ color: status === "Closed" ? COLORS.red : COLORS.darkGrey }}
        >
          {status}
        </span>
      </LI>
      <LI>
        <Title>Format:</Title> {format}
      </LI>
      <LI>
        <Title>Instructor(s):</Title>{" "}
        {instructors.length > 1 ? instructors.join(", ") : instructors[0]}
      </LI>
      <LI>
        <Title>Course Number:</Title> {courseNumber}
      </LI>
      {hasFee && (
        <LI>
          <Title>Supplemental Fee</Title> (This fee is in addition to the
          program cost): ${supplementalFee}
        </LI>
      )}
    </List>
  );
}

export default DetailSection;

const List = styled.ul`
  border-top: 1px solid ${COLORS.lightGrey};
  padding-top: 1rem;
  width: fit-content;
  list-style: circle outside none;
  padding-left: 3rem;
  margin: 0;
  padding-inline-start: 20px;

  ${MEDIA.tablet} {
    padding-left: 1rem;
  }
`;

const LI = styled.li`
  font-size: 1rem;
  font-weight: 400;
  ${FONTS.serif};
  color: ${COLORS.darkGrey};
  margin-bottom: 0.5rem;

  &::marker {
    color: ${COLORS.red};
    font-size: 1rem;
  }
`;

const Title = styled.span`
  font-weight: 700;
  ${FONTS.sans};
  color: ${COLORS.darkBrown};
`;

DetailSection.propTypes = {
  section: shape({}).isRequired,
};

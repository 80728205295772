import { gql } from "@apollo/client";

export const metadataQuery = gql`
  query Metadata {
    precollegeMetadata {
      catalogLabel
      announcement
      announcementColor
      filterOrder
      filters {
        programs
        tagGroups {
          groupName
          tags
        }
        startDates
        courseLengths
        formats
      }
    }
  }
`;

export const coursesQuery = gql`
  query PrecollegeCourses {
    precollegeCourses {
      title
      program
      formats
      dates {
        startDate
        endDate
      }
      synopsis
      synopsisCommonLanguage
      code
      tags
      keywords
      status
      length
    }
  }
`;

export const detailQuery = gql`
  query PrecollegeCourseDetail($id: String!) {
    precollegeCourseDetail(courseCode: $id) {
      title
      description
      prerequisites
      intoCommonLanguage
      closingCommonLanguage
      sections {
        numWeeks
        meetingTimes
        status
        format
        instructors
        courseNumber
        startDate
        endDate
        supplementalFee
      }
    }
  }
`;

import React, { useEffect } from "react";
import styled from "styled-components";
import {
  RoundedSearch,
  MessageBlock,
} from "@BrownUniversity/brown-react-library";
import Offerings from "./offerings";
import FilterMenu from "./filter_menu";
import ViewingTitle from "./viewing_title";
import Spinner from "./spinner";
import FilterTags from "./filter_tags";
import ErrorMessage from "./error_message";
import CatalogStore from "../store";
import { H1, MEDIA } from "./shared_styles";

const filterKeys = [
  "programs",
  "formats",
  "courseLengths",
  "startDates",
  "tagGroups",
  "openOnly",
];

function Landing() {
  const {
    setKeyword,
    keyword,
    offerings,
    coursesLoading,
    metadataLoading,
    metadata,
    setFilterGroups,
    setOpenOnlyFilter,
  } = CatalogStore.useContainer();

  useEffect(() => {
    const storedFilters = sessionStorage.getItem("pc_bu_filter_values");
    const storedSearchTerm = sessionStorage.getItem("pc_bu_keyword") ?? "";
    const storedOpenOnlyFilter =
      sessionStorage.getItem("pc_bu_open_only") ?? "";

    const urlSearchParams = new URLSearchParams(window.location.search);
    const routeParamFiltersArray = [];

    urlSearchParams.forEach((value, key) => {
      routeParamFiltersArray.push({ [key]: value.split(",") });
    });

    const routeParamFilters = Object.assign({}, ...routeParamFiltersArray);
    const paramKeys = Object.keys(routeParamFilters);

    if (paramKeys.length > 0) {
      paramKeys.forEach((key) => {
        if (filterKeys.indexOf(key) === -1) {
          delete routeParamFilters[key];
        }
      });

      filterKeys.forEach((key) => {
        const hasKey = key in routeParamFilters;
        if (!hasKey) {
          routeParamFilters[key] = [];
        }
      });

      if (routeParamFilters.courseLengths.length > 0) {
        const transformedValues = routeParamFilters.courseLengths.map((value) =>
          Number(value),
        );
        routeParamFilters.courseLengths = transformedValues;
      }

      if (routeParamFilters.tagGroups.length > 0) {
        const transformedValues = routeParamFilters.tagGroups.map((value) =>
          value.replace("-", " & ").replace("_", ", "),
        );
        routeParamFilters.tagGroups = transformedValues;
      }

      if (routeParamFilters.openOnly.length > 0) {
        sessionStorage.setItem("pc_bu_open_only", "true");
      }

      delete routeParamFilters.openOnly;

      sessionStorage.setItem(
        "pc_bu_filter_values",
        JSON.stringify(routeParamFilters),
      );
      window.location.href = window.location.href.split("?")[0]; // eslint-disable-line
    }

    if (!metadataLoading && !coursesLoading && storedFilters) {
      setFilterGroups(JSON.parse(storedFilters));
      setKeyword(storedSearchTerm);
      setOpenOnlyFilter(storedOpenOnlyFilter === "true");
    }
  }, [metadataLoading, coursesLoading]); // eslint-disable-line

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (metadataLoading || coursesLoading) {
    return <Spinner />;
  }
  if (!metadata) {
    return <ErrorMessage />;
  }

  const {
    precollegeMetadata: { catalogLabel, announcement, announcementColor },
  } = metadata;

  const count = offerings.length;

  const handleSearchUpdate = (e) => {
    setKeyword(e.target.value);
    sessionStorage.setItem("pc_bu_keyword", e.target.value);
  };

  const handleSearchClose = () => {
    setKeyword("");
    sessionStorage.setItem("pc_bu_keyword", "");
  };

  return (
    <Container>
      <H1>{catalogLabel}</H1>
      <Wrapper>
        <FilterMenu count={count} />
        <ContentWrapper>
          {announcement && (
            <MessageBlock message={announcement} type={announcementColor} />
          )}
          <SearchWrapper>
            <ViewingTitle
              message={`currently viewing ${count} course${
                count === 1 ? "" : "s"
              }`}
            />
            <RoundedSearch
              value={keyword}
              onChange={handleSearchUpdate}
              onClose={handleSearchClose}
            />
          </SearchWrapper>
          <FilterTags />
          <Offerings offerings={offerings} />
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
}

export default Landing;

const Container = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 220px auto 3rem auto;

  ${MEDIA.tablet} {
    margin: 100px auto 3rem auto;
    width: 95%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  margin-top: 2rem;

  ${MEDIA.tablet} {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  flex: 3;
  max-width: 700px;

  ${MEDIA.tablet} {
    max-width: 100%;
    width: 100%;
    flex: 0;
  }
`;

const SearchWrapper = styled.div`
  margin-bottom: 2rem;
`;

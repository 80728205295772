import styled from "styled-components";

export const COLORS = {
  darkBrown: "#422d22",
  brown: "#4e3629",
  darkGrey: "#434343",
  lightGrey: "#ededed",
  red: "#c00404",
  gold: "#ffc72c",
  darkEmerald: "#008672",
  navy: "#003C71",
  white: "#fff",
  lightGold: "#fef1cd",
};

export const MEDIA = {
  tablet: "@media(max-width: 768px)",
  phone: "@media(max-width: 550px)",
  smallScreen: "@media(max-width: 950px)",
  largeScreen: "@media(max-width: 1250px)",
  motion: "@media screen and (prefers-reduced-motion: no-preference)",
};

export const FONTS = {
  serif: 'font-family: "Minion Pro", Georgia, serif;',
  sans: "font-family: CircularStd, Arial, Helvetica, sans-serif;",
};

export const H1 = styled.h1`
  ${FONTS.serif}
  font-weight: 700;
  color: ${COLORS.brown};
  font-size: 3rem;
  line-height: 2.75rem;

  ${MEDIA.tablet} {
    font-size: 2rem;
  }
`;

export const H2 = styled.h2`
  ${FONTS.sans}
  font-weight: 700;
  color: ${COLORS.brown};
`;

export const H3 = styled.h3`
  ${FONTS.sans}
  font-weight: 400;
  color: ${COLORS.brown};
`;

export const A = styled.a`
  text-decoration: none;
  color: ${COLORS.brown};
  font-size: 16px;
`;

import React from "react";
import styled from "styled-components";
import { Loader } from "@BrownUniversity/brown-react-library";

function Spinner() {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
}

export default Spinner;

const LoaderContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  margin: auto;
`;

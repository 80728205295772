import React from "react";
import styled from "styled-components";
import { SearchLink } from "@BrownUniversity/brown-react-library";

function Link() {
  return (
    <SearchWrap>
      <SearchLink url="https://precollege.brown.edu/#flyout_search" />
    </SearchWrap>
  );
}

export default Link;

const SearchWrap = styled.div`
  padding-left: 1rem;
`;

import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { useQuery } from "@apollo/client";
import { coursesQuery, metadataQuery } from "../queries";
import {
  filteredByKeywords,
  filterList,
  filterByOpenOnly,
  multipleFilters,
  removeDulpicates,
} from "../helpers";

const useCatalogStore = () => {
  const { loading: coursesLoading, data: courses } = useQuery(coursesQuery);
  const { loading: metadataLoading, data: metadata } = useQuery(metadataQuery);

  const [allCourses, setAllCourses] = useState([]);
  const [offerings, setOfferings] = useState([]);

  const [filterGroups, setFilterGroups] = useState({});
  const [openOnlyFilter, setOpenOnlyFilter] = useState(false);
  const [currentFilters, setCurrentFilters] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [onlyOpenData, setOnlyOpenData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);

  useEffect(() => {
    if (!metadataLoading && metadata) {
      const {
        precollegeMetadata: { filters },
      } = metadata;

      const filterContainers = Object.keys(filters)
        .filter((name) => name !== "__typename")
        .reduce((obj, key) => Object.assign(obj, { [key]: [] }), {});

      setFilterGroups(filterContainers);
    }
  }, [metadata, metadataLoading]);

  useEffect(() => {
    if (!coursesLoading && courses) {
      setOfferings(courses.precollegeCourses);
      setAllCourses(courses.precollegeCourses);
    }
  }, [coursesLoading]); // eslint-disable-line

  useEffect(() => {
    if (keyword.length > 2 && !coursesLoading) {
      setSearchedData(filteredByKeywords(keyword, allCourses));
    } else if (keyword === "" && !coursesLoading) {
      setSearchedData([]);
    }
  }, [keyword]); // eslint-disable-line

  useEffect(() => {
    const noFilters =
      Object.values(filterGroups)
        .map((filterValues) => filterValues.length > 0)
        .filter((item) => item).length === 0;

    const filterKeys = Object.entries(filterGroups)
      .map(([filterName, filterValues]) =>
        filterValues.flatMap((filterItem) => ({
          filter: filterItem,
          group: filterName,
        })),
      )
      .flat();

    setCurrentFilters(filterKeys);

    const allFiltersResults = Object.entries(filterGroups).map(
      ([filterName, filterValues]) => {
        const results = filterValues.flatMap((filterItem) =>
          filterList(filterName, filterItem, allCourses),
        );
        return results;
      },
    );

    setFilteredData(
      noFilters
        ? []
        : removeDulpicates(
            multipleFilters(allFiltersResults, filterGroups, allCourses),
          ),
    );
  }, [filterGroups]); // eslint-disable-line

  useEffect(() => {
    setOnlyOpenData(openOnlyFilter ? filterByOpenOnly(offerings) : []);
  }, [openOnlyFilter]); // eslint-disable-line

  useEffect(() => {
    const hasFilters = currentFilters.length > 0;
    const hasKeyword = keyword !== "";
    const filterTypeCount = [openOnlyFilter, hasFilters, hasKeyword].filter(
      (item) => item,
    ).length;

    if (filterTypeCount === 0) {
      setOfferings(allCourses);
    } else if (filterTypeCount === 1) {
      const filterData = hasFilters ? filteredData : onlyOpenData;
      const data = hasKeyword ? searchedData : filterData;

      setOfferings(data);
    } else {
      const filteredAndSearchedItems = [
        ...searchedData,
        ...filteredData,
      ].filter((item) => item);

      const reducedFilteredItems = filteredAndSearchedItems.filter(
        (item, index) => filteredAndSearchedItems.indexOf(item) !== index,
      );

      const results = openOnlyFilter
        ? onlyOpenData.filter(
            (item) => filteredAndSearchedItems.indexOf(item) !== -1,
          )
        : reducedFilteredItems;

      setOfferings(results);
    }
  }, [searchedData, filteredData, onlyOpenData]); // eslint-disable-line

  const clearAllFilters = () => {
    const filterObject = {};
    Object.keys(filterGroups).forEach((filterName) => {
      filterObject[filterName] = [];
    });
    setFilterGroups(filterObject);
    setOpenOnlyFilter(false);
    sessionStorage.setItem("pc_bu_filter_values", JSON.stringify(filterObject));
  };

  return {
    keyword,
    setKeyword,
    coursesLoading,
    offerings,
    metadata,
    metadataLoading,
    filterGroups,
    setFilterGroups,
    openOnlyFilter,
    setOpenOnlyFilter,
    currentFilters,
    clearAllFilters,
  };
};

const CatalogStore = createContainer(useCatalogStore);
export default CatalogStore;

import React from "react";
import { node } from "prop-types";
import { FlexFooter, SkipLink } from "@BrownUniversity/brown-react-library";
import { footer } from "../data";
import Header from "./header";

function Layout({ children }) {
  return (
    <>
      <SkipLink styleOverride="position:fixed;transform:translate(-50%,-800%);" />
      <Header />
      <main id="main-content">{children}</main>
      <FlexFooter
        topNavList={footer.top}
        bottomNavList={footer.bottom}
        email={footer.email}
        socialLinks={footer.social}
      />
    </>
  );
}

export default Layout;

Layout.propTypes = {
  children: node.isRequired,
};

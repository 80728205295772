import React from "react";
import styled from "styled-components";
import { ListCheckItem } from "@BrownUniversity/brown-react-library";
import CatalogStore from "../store";
import FilterGroup from "./filter_group";
import { camelToTitleCase, setFilter } from "../helpers";
import { MEDIA, H2, COLORS } from "./shared_styles";

function Filters() {
  const {
    metadata,
    setOpenOnlyFilter,
    setFilterGroups,
    filterGroups,
    openOnlyFilter,
    currentFilters,
  } = CatalogStore.useContainer();

  const {
    precollegeMetadata: { filters, filterOrder },
  } = metadata;

  const currentFilterNames = currentFilters.map((filter) => filter.filter);

  const handleOpenOnlyClick = () => {
    setOpenOnlyFilter((state) => !state);
  };

  const orderedFilterGroups = {};
  filterOrder.forEach((filter) => {
    orderedFilterGroups[filter] = filters[filter];
  });

  return (
    <Container>
      <H2>Filters</H2>
      <ShowFilterContainer>
        <ListCheckItem
          name="Show Open Courses Only"
          onClick={handleOpenOnlyClick}
          checked={openOnlyFilter}
        />
      </ShowFilterContainer>
      {Object.entries(orderedFilterGroups).map(([filterName, filterValues]) => {
        if (filterName === "__typename") {
          return null;
        }

        const handleTagGroupItemClick = (value) => {
          setFilter(value, "tagGroups", filterGroups, setFilterGroups);
        };

        const handleGroupItemClick = (value) => {
          setFilter(value, filterName, filterGroups, setFilterGroups);
        };

        if (filterName === "tagGroups") {
          return filterValues.map((g) => (
            <FilterGroup
              key={g.groupName}
              name={g.groupName}
              filters={g.tags}
              onClick={handleTagGroupItemClick}
              currentFilters={currentFilterNames}
              selectAll
            />
          ));
        }

        return (
          <FilterGroup
            key={filterName}
            name={camelToTitleCase(filterName)}
            filters={filterValues}
            onClick={handleGroupItemClick}
            currentFilters={currentFilterNames}
          />
        );
      })}
    </Container>
  );
}

export default Filters;

const Container = styled.div`
  flex: 1;
  margin-right: 2.5rem;
  max-width: 300px;

  ${MEDIA.tablet} {
    max-width: 100%;
    margin: 0;
    flex: 0;
  }
`;

const ShowFilterContainer = styled.div`
  padding-bottom: 1rem;
  border-bottom: 1px solid ${COLORS.lightGrey};
`;

import React from "react";
import styled from "styled-components";

export default function ErrorMessage() {
  return (
    <Container>
      <p>Unable to connect at this time, please try again later.</p>
      <p>
        If the problem persists, please email{" "}
        <a href="mailto:precollege@brown.edu">precollege@brown.edu</a>
        {" for "}
        assistance.
      </p>
    </Container>
  );
}

const Container = styled.div`
  height: 500px;
  width: 100%;
  padding-top: 200px;
  text-align: center;
`;

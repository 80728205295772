import React, { useState } from "react";
import styled from "styled-components";
import { bool } from "prop-types";
import {
  FadeLink,
  DownBounceNavItem,
  HeaderDropdownMenu,
} from "@BrownUniversity/brown-react-library";
import { MEDIA } from "../shared_styles";
import { header } from "../../data";

function Top({ hide }) {
  const [activeMenu, setActiveMenu] = useState("");

  return (
    <Wrapper hide={hide}>
      <Container hide={hide}>
        <FadeLink link={header.home} />
        <RightNavWrapper>
          <FadeLink link={header.login} italic tightHover uppercase={false} />
          <Dash>&mdash;</Dash>
          <FadeLink link={header.about} italic tightHover uppercase={false} />
          <Dash>&mdash;</Dash>
          <HeaderDropdownMenu
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            name="for you"
            position={{ top: "35px", left: "-150px" }}
            links={header.forYou}
          >
            <DownBounceNavItem
              title="for you"
              onHover={() => null}
              aria-haspopup="true"
              aria-expanded={activeMenu === "for you"}
            />
          </HeaderDropdownMenu>
        </RightNavWrapper>
      </Container>
    </Wrapper>
  );
}

export default Top;

const Wrapper = styled("div").withConfig({
  shouldForwardProp: (props) => !["hide"].includes(props),
})`
  height: ${({ hide }) => (hide ? 0 : "55px")};
  background-color: ${({ hide }) => (hide ? "#fff" : "#c10506")};
  padding: 0 1rem;

  ${MEDIA.motion} {
    transition: all 0.35s ease;
  }

  ${MEDIA.tablet} {
    display: none;
  }
`;

const Container = styled("div").withConfig({
  shouldForwardProp: (props) => !["hide"].includes(props),
})`
  height: 55px;
  justify-content: space-between;
  align-items: center;
  max-width: 1350px;
  margin: auto;
  display: ${({ hide }) => (hide ? "none" : "flex")};
`;

const RightNavWrapper = styled.div`
  display: flex;
  min-width: 200px;
  justify-content: space-between;
  align-items: center;
`;

const Dash = styled.span`
  color: white;
  opacity: 0.8;
  margin-top: -4px;
`;

Top.propTypes = {
  hide: bool.isRequired,
};

import React from "react";
import styled from "styled-components";
import { bool } from "prop-types";
import Logo from "./logo";
import MobileNav from "./mobile_nav";
import DesktopNav from "./desktop_nav";
import SearchLink from "./search_link";
import navigationItems from "../../precollege_nav_data";

function Bottom({ show }) {
  const isMobileSize = window?.matchMedia("(max-width: 768px)").matches;

  return (
    <Wrapper>
      <Container isMobileSize={isMobileSize}>
        <Logo show={show} />
        <NavWrapper>
          {isMobileSize ? (
            <MobileNav navigationItems={navigationItems} />
          ) : (
            <>
              <DesktopNav navigationItems={navigationItems} />
              <SearchLink />
            </>
          )}
        </NavWrapper>
      </Container>
    </Wrapper>
  );
}

export default Bottom;

const Wrapper = styled.div`
  border-bottom: 1px solid #f0f3f5;
  width: 100%;
  background-color: #fff;
`;

const Container = styled("div").withConfig({
  shouldForwardProp: (props) => !["isMobileSize"].includes(props),
})`
  max-width: 1350px;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: ${({ isMobileSize }) =>
    isMobileSize ? "row-reverse" : "row"};
  justify-content: space-between;
  align-items: center;
`;

const NavWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
`;

Bottom.propTypes = {
  show: bool.isRequired,
};

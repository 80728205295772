import React from "react";
import Router from "./components/router";
import Layout from "./components/layout";

function App() {
  return (
    <Layout>
      <Router />
    </Layout>
  );
}

export default App;

import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  HtmlRenderer,
  OutlineButtonLink,
} from "@BrownUniversity/brown-react-library";
import { detailQuery } from "../queries";
import DetailSection from "./detail_section";
import Spinner from "./spinner";
import { H2, A, FONTS, COLORS, MEDIA } from "./shared_styles";

function Detail() {
  const { id } = useParams();

  const { loading, data } = useQuery(detailQuery, {
    variables: { id },
  });

  if (loading) {
    return <Spinner />;
  }

  const {
    title,
    description,
    prerequisites,
    sections,
    intoCommonLanguage,
    closingCommonLanguage,
  } = data.precollegeCourseDetail;

  return (
    <Wrapper>
      <H1>
        <HtmlRenderer HTMLstring={title} clean />
      </H1>
      <ContentContainer>
        <DetailContainer>
          <A href="/">« Return to Course Catalog</A>
          <H2>Course Description</H2>
          {intoCommonLanguage && (
            <HTMLcontainer>
              <HtmlRenderer HTMLstring={`<p>${intoCommonLanguage}</p>`} />
            </HTMLcontainer>
          )}
          <HTMLcontainer>
            <HtmlRenderer HTMLstring={description} clean />
          </HTMLcontainer>
          {closingCommonLanguage && (
            <HTMLcontainer>
              <HtmlRenderer HTMLstring={`<p>${closingCommonLanguage}</p>`} />
            </HTMLcontainer>
          )}
          {prerequisites && (
            <>
              <H2>Prerequisites</H2>
              <HtmlRenderer HTMLstring={prerequisites} clean />
            </>
          )}
          <br />
        </DetailContainer>
        <SidebarContainer>
          <H2>Course Information</H2>
          <SideNav>
            <OutlineButtonLink
              href="https://precollege.brown.edu/programs"
              text="VISIT PROGRAM PAGE"
            />
            <OutlineButtonLink
              href="https://precollege.brown.edu/costs-aid/program-dates-and-costs"
              text="VIEW COURSE PRICING"
            />
            <OutlineButtonLink
              href="https://precollege.brown.edu/programs#apply"
              text="LEARN HOW TO APPLY"
            />
            <H2>Sections</H2>
            {sections.map((section) => (
              <DetailSection section={section} key={section.courseNumber} />
            ))}
          </SideNav>
        </SidebarContainer>
      </ContentContainer>
    </Wrapper>
  );
}

export default Detail;

const Wrapper = styled.div`
  max-width: 900px;
  margin: auto;
  font-family: "Minion Pro", Georgia, serif;
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: #434343;
  margin-top: 220px;

  ${MEDIA.tablet} {
    margin: 100px 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  margin-top: 2rem;

  ${MEDIA.tablet} {
    flex-direction: column;
  }
`;

const DetailContainer = styled.div`
  flex: 3;
  padding-right: 2rem;

  ${MEDIA.tablet} {
    padding: 0;
  }
`;

const SidebarContainer = styled.div`
  flex: 1;
  margin-left: 2rem;
  padding-bottom: 2rem;

  ${MEDIA.tablet} {
    margin: 0;
  }
`;

const SideNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const HTMLcontainer = styled.div`
  ${FONTS.serif}
  line-height: 1.5rem;
  letter-spacing: 0.5px;
  color: ${COLORS.darkGrey};
`;

const H1 = styled.h1`
  ${FONTS.sans}
  font-weight: 700;
  color: ${COLORS.brown};
  font-size: 3rem;
  line-height: 2.75rem;

  ${MEDIA.tablet} {
    font-size: 2rem;
  }
`;

import React from "react";
import { bool } from "prop-types";
import styled from "styled-components";
import image from "./logo.png";
import { MEDIA } from "../shared_styles";

export default function Logo({ show }) {
  return (
    <LogoWrap
      href="https://precollege.brown.edu/"
      data-testid="header-logo-link"
    >
      <Image
        src={image}
        alt="Brown University Logo"
        size={show ? "100" : "182"}
        data-testid="header-logo"
      />
    </LogoWrap>
  );
}

const LogoWrap = styled.a`
  margin: auto 0;
  ${MEDIA.smallScreen} {
    padding-left: 1rem;
  }
`;

const Image = styled("img").withConfig({
  shouldForwardProp: (props) => !["size"].includes(props),
})`
  transition: width 0.5s;
  width: ${({ size }) => size}px;
  margin: 0 auto;

  ${MEDIA.smallScreen} {
    width: 88px;
    transform: translate(0, 3px);
  }
`;

Logo.propTypes = {
  show: bool.isRequired,
};

import React, { useState } from "react";
import { number } from "prop-types";
import styled from "styled-components";
import { HamburgerMenuToggle } from "@BrownUniversity/brown-react-library";
import Filters from "./filters";
import { MEDIA, COLORS, H2 } from "./shared_styles";

function FilterMenu({ count }) {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <MobileWrapper>
        <ToggleContainer>
          <H2>Filters ({count} Courses)</H2>{" "}
          <HamburgerMenuToggle
            onOpen={() => setToggle(true)}
            onClose={() => setToggle(false)}
          />
        </ToggleContainer>
        <TransitionWrapper toggle={toggle}>
          {toggle && <Filters />}
        </TransitionWrapper>
      </MobileWrapper>
      <DesktopWrapper>
        <Filters />
      </DesktopWrapper>
    </>
  );
}

export default FilterMenu;

const ToggleContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${COLORS.lightGrey};
  align-items: center;
`;

const TransitionWrapper = styled("div").withConfig({
  shouldForwardProp: (props) => !["toggle"].includes(props),
})`
  height: ${({ toggle }) => (toggle ? "100%" : "0px")};
  opacity: ${({ toggle }) => (toggle ? 1 : 0)};

  ${MEDIA.motion} {
    transition: all 0.35s ease;
  }
`;

const MobileWrapper = styled.div`
  display: none;
  position: relative;

  ${MEDIA.tablet} {
    display: block;
  }
`;

const DesktopWrapper = styled.div`
  display: block;

  ${MEDIA.tablet} {
    display: none;
  }
`;

FilterMenu.propTypes = {
  count: number.isRequired,
};

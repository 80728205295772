import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Top from "./top";
import Bottom from "./bottom";

function Header() {
  const [scrollMark, setScrollMark] = useState(0);

  useEffect(() => {
    const scrollCheck = () => {
      setScrollMark(window.pageYOffset);
    };
    const watchScroll = () => {
      window.addEventListener("scroll", scrollCheck);
    };

    watchScroll();

    return () => window.removeEventListener("scroll", scrollCheck);
  }, []);

  const show = scrollMark > 200;

  return (
    <Head>
      <Top hide={show} />
      <Bottom show={show} />
    </Head>
  );
}

export default Header;

const Head = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 2;
`;

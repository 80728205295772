import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "@BrownUniversity/brown-react-library";
import CatalogStore from "./store";
import client from "./helpers/graphql_client";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <ErrorBoundary message="No offerings available to display.">
          <CatalogStore.Provider>
            <App />
          </CatalogStore.Provider>
        </ErrorBoundary>
      </BrowserRouter>
    </ApolloProvider>
  </React.StrictMode>,
);

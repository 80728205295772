import React from "react";
import styled from "styled-components";
import image from "./bear-in-megaphone.jpeg";

function Four0Four() {
  return (
    <Wrapper>
      <Container>
        <Title>404 Error - Page Not Found</Title>
        <img
          src={image}
          alt="a perplexed bear looking in a megaphone"
          style={{ width: "100%" }}
        />
      </Container>
    </Wrapper>
  );
}

export default Four0Four;

const Wrapper = styled.div`
  height: 60vh;
  margin-top: 200px;
`;

const Container = styled.div`
  margin: 2rem auto;
  width: fit-content;
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 2rem;
  text-align: center;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  color: #4e3629;
`;

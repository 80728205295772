import dayjs from "dayjs";

export const camelToTitleCase = (string) =>
  string
    .replace(/([A-Z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase())
    .trim();

export const removeDulpicates = (array) => [...new Set(array)];

const filteredByKeyword = (key, array) =>
  array.flatMap((course, index) => {
    const keywordFilter = course.keywords
      ?.split(" ")
      .some((words) => words.toLowerCase().includes(key.toLowerCase()));

    const titleFilter = course.title
      ?.split(" ")
      .some((words) => words.toLowerCase().includes(key.toLowerCase()));

    const synopsisFilter = course.synopsis
      ?.split(" ")
      .some((words) => words.toLowerCase().includes(key.toLowerCase()));

    return keywordFilter || titleFilter || synopsisFilter ? array[index] : [];
  });

const findDuplicates = (array1 = [], array2 = []) => {
  const duplicates = Array.from(
    [...array1, ...array2].reduce(
      (acc, v, i, arr) => (arr.indexOf(v) !== i ? acc.add(v) : acc),
      new Set(),
    ),
  );
  return array2.length === 0 ? [] : duplicates;
};

export const filteredByKeywords = (key, array) => {
  const keys = key.split(" ").filter((i) => i !== "");

  if (keys.length === 1) {
    return filteredByKeyword(key.trim(), array);
  }

  const multiFilter = keys.map((k) => filteredByKeyword(k, array));

  let results = [];
  const numberOfKeywords = multiFilter.length;

  multiFilter.forEach((arr, index) => {
    results = findDuplicates(multiFilter[0], multiFilter[1]);
    const isLastArray = index === numberOfKeywords - 1;
    if (isLastArray) {
      results = findDuplicates(results, arr);
      return;
    }
    if (arr.length > results.length) return;
    results = findDuplicates(results, multiFilter[index + 1]);
  });

  return results;
};

const filteredByProgram = (key, array) =>
  array.flatMap((course, index) => {
    const programFilter = course.program === key;

    return programFilter ? array[index] : [];
  });

const filteredByTags = (key, array) =>
  array.flatMap((course, index) => {
    const tagFilter = course.tags.some((tag) => tag.includes(key));
    return tagFilter ? array[index] : [];
  });

const filteredByDates = (key, array) =>
  array.flatMap((course, index) => {
    const dateFilter = course.dates.map((date) => date.startDate).includes(key);

    return dateFilter ? array[index] : [];
  });

const filteredByDuration = (key, array) =>
  array.flatMap((course, index) => {
    const durationFilter = course.length.includes(key);

    return durationFilter ? array[index] : [];
  });

const filteredByFormat = (key, array) =>
  array.flatMap((course, index) => {
    const formatFilter = course.formats.includes(key);

    return formatFilter ? array[index] : [];
  });

export const filterList = (group, key, array) => {
  switch (group) {
    case "programs":
      return { type: "program", value: filteredByProgram(key, array) };
    case "tagGroups":
      return { type: "groups", value: filteredByTags(key, array) };
    case "startDates":
      return { type: "dates", value: filteredByDates(key, array) };
    case "courseLengths":
      return { type: "duration", value: filteredByDuration(key, array) };
    case "formats":
      return { type: "formats", value: filteredByFormat(key, array) };
    default:
      return [];
  }
};

export const multipleFilters = (array, filters, allCourses) => {
  let results = allCourses;
  let hasDateFilter = false;
  let hasDurationFilter = false;
  let hasFormatFilter = false;
  let hasTagsFilters = false;
  let hasProgramsFiters = false;

  array.forEach((filterGroup) => {
    if (filterGroup.length === 0) return;

    if (filterGroup[0].type === "dates") {
      hasDateFilter = true;
    }

    if (filterGroup[0].type === "duration") {
      hasDurationFilter = true;
    }

    if (filterGroup[0].type === "formats") {
      hasFormatFilter = true;
    }

    if (filterGroup[0].type === "groups") {
      hasTagsFilters = true;
    }

    if (filterGroup[0].type === "program") {
      hasProgramsFiters = true;
    }
  });

  const withTagsFiltered = () =>
    results
      .flatMap((course) => {
        const hasDuration = course.tags.some((d) =>
          filters.tagGroups.includes(d),
        );
        return hasDuration ? [course] : [];
      })
      .filter((i) => i)
      .flat();

  const withFormatsFiltered = () =>
    results
      .flatMap((course) => {
        const hasFormat = course.formats.some((d) =>
          filters.formats.includes(d),
        );
        return hasFormat ? [course] : [];
      })
      .filter((i) => i)
      .flat();

  const withDurationFiltered = () =>
    results
      .flatMap((course) => {
        const hasDuration = course.length.some((d) =>
          filters.courseLengths.includes(d),
        );
        return hasDuration ? [course] : [];
      })
      .filter((i) => i)
      .flat();

  const withProgramsFiltered = () =>
    results
      .flatMap((course) => {
        const hasPrograms = filters.programs.includes(course.program);
        return hasPrograms ? [course] : [];
      })
      .filter((i) => i)
      .flat();

  const withDatesFiltered = () =>
    results
      .flatMap((course) => {
        const courseStartDates = course
          ? course.dates.map((date) => date.startDate)
          : [];
        const hasDates = courseStartDates.some((d) =>
          filters.startDates.includes(d),
        );

        return hasDates ? [course] : [];
      })
      .filter((i) => i)
      .flat();
  if (hasDateFilter) results = withDatesFiltered();
  if (hasFormatFilter) results = withFormatsFiltered();
  if (hasTagsFilters) results = withTagsFiltered();
  if (hasProgramsFiters) results = withProgramsFiltered();
  if (hasDurationFilter) results = withDurationFiltered();

  return results;
};

export const filterByOpenOnly = (array) => {
  const filteredResults = array.filter((item) => {
    const closedRemoved = item.status.filter(
      (status) =>
        status === "Open for Registration" || status === "Nearing Capacity",
    );
    return closedRemoved.length > 0;
  });

  return filteredResults;
};

export const setFilter = (
  filterValue,
  filterGroup,
  filterGroups,
  setFilterGroups,
) => {
  const prevFilterGroupValues = filterGroups[filterGroup];
  if (prevFilterGroupValues.includes(filterValue)) {
    prevFilterGroupValues.splice(prevFilterGroupValues.indexOf(filterValue), 1);
  } else {
    prevFilterGroupValues.push(filterValue);
  }

  const newFilterValues = { ...filterGroups };
  setFilterGroups(newFilterValues);
  sessionStorage.setItem(
    "pc_bu_filter_values",
    JSON.stringify(newFilterValues),
  );
};

export const formatDate = (dateString, long = true) => {
  const longFormat = "MMMM DD, YYYY";
  const shortFormat = "MMMM DD";

  const formattedDate = dayjs(dateString).format(
    long ? longFormat : shortFormat,
  );

  return formattedDate;
};

export const getDurationTag = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  const timeDifference = endDate.getTime() - startDate.getTime();
  const difference = Math.round(timeDifference / (1000 * 3600 * 24));
  const weekNumber = Math.round(difference / 7);
  const plural = weekNumber > 1 ? "weeks" : "week";
  return `${weekNumber} ${plural}`;
};

export const formattedTitle = (str, name) => {
  switch (name) {
    case "Start Dates":
      return formatDate(str);
    case "Course Lengths":
      return `${str} week${str === 1 ? "" : "s"}`;
    default:
      return str;
  }
};

import React, { useState } from "react";
import styled from "styled-components";
import { arrayOf, shape } from "prop-types";
import { HeaderDropdownMenu } from "@BrownUniversity/brown-react-library";

function DesktopNav({ navigationItems }) {
  const [activeMenu, setActiveMenu] = useState("");

  return (
    <>
      {navigationItems.map((link) =>
        link.links.length > 0 ? (
          <HeaderDropdownMenu
            key={link.title}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            links={link.links}
            name={link.title}
            position={{ top: "20px", left: "-100px" }}
          >
            <li>
              <Link
                href={link.url}
                aria-haspopup="true"
                aria-expanded={activeMenu === link.title}
              >
                {link.title}
              </Link>
            </li>
          </HeaderDropdownMenu>
        ) : (
          <li key={link.title}>
            <Link href={link.url}>{link.title}</Link>
          </li>
        ),
      )}
    </>
  );
}

export default DesktopNav;

export const Link = styled.a`
  text-decoration: none;
  padding: 11px 6px;
  margin-bottom: 0;
  list-style-type: none;
  margin: 0;
  font-family: CircularStd, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #3c3c3c;

  &:hover {
    color: #c00404;
  }
`;

DesktopNav.propTypes = {
  navigationItems: arrayOf(shape({})).isRequired,
};

import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "./landing";
import Detail from "./detail";
import Four0Four from "./404";

function Router() {
  return (
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route path="/detail/:id" element={<Detail entities="Categories" />} />
      <Route path="/catalog" element={<Navigate replace to="/" />} />
      <Route path="*" element={<Four0Four />} />
    </Routes>
  );
}

export default Router;

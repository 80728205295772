import React, { useState } from "react";
import styled from "styled-components";
import { arrayOf, shape } from "prop-types";
import {
  HamburgerMenuToggle,
  AccordionContainer,
} from "@BrownUniversity/brown-react-library";
import SearchLink from "./search_link";
import { Link } from "./desktop_nav";
import { header } from "../../data";

function MobileNav({ navigationItems }) {
  const [toggle, setToggle] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <HamburgerMenuToggle
        onOpen={() => setToggle(true)}
        onClose={() => setToggle(false)}
      />
      {toggle && (
        <MenuWrapper>
          <div style={{ padding: "0 10px" }}>
            {navigationItems.map((link) =>
              link.links.length > 0 ? (
                <AccordionContainer name={link.title} key={link.title}>
                  <NavWrapper>
                    <li>
                      <Link href={link.url}>{link.title}</Link>
                    </li>
                    {link.links.map((path) => (
                      <li key={path.title}>
                        <Link href={path.url}>{path.title}</Link>
                      </li>
                    ))}
                  </NavWrapper>
                </AccordionContainer>
              ) : (
                <LinkWrapper key={link.title}>
                  <Link href={link.url}>{link.title}</Link>
                </LinkWrapper>
              ),
            )}
            <div
              style={{
                borderTop: "1px solid #ededed",
                height: "1px",
                width: "100%",
              }}
            />
            <AccordionContainer name="For You">
              <NavWrapper>
                {header.forYou.map((path) => (
                  <li key={path.title}>
                    <Link href={path.url}>{path.title}</Link>
                  </li>
                ))}
              </NavWrapper>
            </AccordionContainer>
            <IconContainer>
              <LinkWrapper>
                <Link href="https://brownuniversity.force.com/PCPortal/TX_SiteLogin?startURL=/PCPortal/">
                  Login
                </Link>
                <Link href="https://precollege.brown.edu/about">About</Link>
                <Link href="https://www.brown.edu">brown.edu</Link>
              </LinkWrapper>
              <SearchLink />
            </IconContainer>
          </div>
        </MenuWrapper>
      )}
    </div>
  );
}

export default MobileNav;

const MenuWrapper = styled.div`
  position: absolute;
  left: -16px;
  top: 50px;
  background-color: white;
  width: 100vw;
  max-height: 80vh;
  overflow: scroll;
  border-bottom: 1px solid dimgrey;
  box-shadow:
    0px 4px 2.7px rgba(0, 0, 0, 0.065),
    0px 9.5px 6.5px rgba(0, 0, 0, 0.093),
    0px 17.9px 12.3px rgba(0, 0, 0, 0.115),
    0px 31.9px 21.9px rgba(0, 0, 0, 0.137),
    0px 59.7px 40.9px rgba(0, 0, 0, 0.165),
    0px 143px 98px rgba(0, 0, 0, 0.23);
`;

const NavWrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin: 1.5rem 0 1.5rem 1.5rem;
  }
`;

const LinkWrapper = styled.li`
  margin: 2rem 0 2rem 1.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 2rem;
  align-items: center;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`;

MobileNav.propTypes = {
  navigationItems: arrayOf(shape({})).isRequired,
};

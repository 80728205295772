import React from "react";
import styled from "styled-components";
import { FilterTag } from "@BrownUniversity/brown-react-library";
import CatalogStore from "../store";
import { setFilter, formattedTitle, camelToTitleCase } from "../helpers";

function FilterTags() {
  const {
    currentFilters,
    openOnlyFilter,
    setOpenOnlyFilter,
    filterGroups,
    setFilterGroups,
    clearAllFilters,
  } = CatalogStore.useContainer();

  const onClearAllClicked = () => {
    clearAllFilters();
  };

  const handleOpenOnlyClick = () => {
    setOpenOnlyFilter(false);
  };

  return (
    <TagsContainer
      empty={
        [...currentFilters, openOnlyFilter ? " " : null].filter(
          (item) => item,
        ) === 0
      }
    >
      {openOnlyFilter && (
        <FilterTag filter="Open Courses Only" onClick={handleOpenOnlyClick} />
      )}
      {currentFilters.map((filter) => {
        const handleFilterClick = () => {
          setFilter(filter.filter, filter.group, filterGroups, setFilterGroups);
        };
        return (
          <FilterTag
            key={filter.filter}
            filter={formattedTitle(
              filter.filter,
              camelToTitleCase(filter.group),
            )}
            onClick={handleFilterClick}
          />
        );
      })}
      {[...currentFilters, openOnlyFilter ? " " : null].filter((item) => item)
        .length > 1 && (
        <FilterTag
          clearAll
          filter="Clear All Filters"
          onClick={onClearAllClicked}
          aria-label="Clear All Filters"
        />
      )}
    </TagsContainer>
  );
}

export default FilterTags;

const TagsContainer = styled("div").withConfig({
  shouldForwardProp: (props) => !["empty"].includes(props),
})`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${({ empty }) => (empty ? 0 : "2rem")};
`;

import React from "react";
import { string } from "prop-types";
import styled from "styled-components";
import { COLORS, FONTS } from "./shared_styles";

function ViewingTitle({ message }) {
  return <Viewing aria-live="polite">{message}</Viewing>;
}

export default ViewingTitle;

const Viewing = styled.p`
  font-size: 1.75rem;
  color: ${COLORS.brown};
  text-transform: capitalize;
  ${FONTS.sans}
`;

ViewingTitle.propTypes = {
  message: string.isRequired,
};

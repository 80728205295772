import React from "react";
import { string, func, arrayOf, number, oneOfType, bool } from "prop-types";
import {
  ListCheckItem,
  AccordionContainer,
} from "@BrownUniversity/brown-react-library";
import { formattedTitle } from "../helpers";

function FilterGroup({ name, filters, onClick, currentFilters, selectAll }) {
  const renderFilters = () => {
    const allSelected =
      filters.length ===
      currentFilters.filter((current) => filters.includes(current)).length;

    const handleAllClick = () => {
      filters.forEach((filter) => {
        if (currentFilters.includes(filter) && !allSelected) {
          onClick(filter);
        }
        onClick(filter);
      });
    };

    return (
      <>
        {selectAll && (
          <ListCheckItem
            name={`Select All ${name}`}
            onClick={handleAllClick}
            checked={allSelected}
            verticalPadding="0.75rem"
            disabled={allSelected}
            maxTextWidth="250px"
          />
        )}
        {filters.map((filter) => {
          const handleClick = () => onClick(filter);
          return (
            <ListCheckItem
              name={formattedTitle(filter, name)}
              key={filter}
              onClick={handleClick}
              checked={currentFilters.includes(filter)}
              verticalPadding="0.75rem"
            />
          );
        })}
      </>
    );
  };

  return (
    <div key={name}>
      <AccordionContainer name={name} fontSize="1rem">
        <div style={{ paddingBottom: "1.5rem" }}>{renderFilters()}</div>
      </AccordionContainer>
    </div>
  );
}

export default FilterGroup;

FilterGroup.propTypes = {
  name: string.isRequired,
  filters: arrayOf(oneOfType([number, string])),
  onClick: func.isRequired,
  currentFilters: arrayOf(oneOfType([number, string])),
  selectAll: bool,
};

FilterGroup.defaultProps = {
  filters: [],
  currentFilters: [],
  selectAll: false,
};
